


























































































































































































import { computed, defineComponent } from "@vue/composition-api";
import ProfileCard from "@/views/ProfilePage/ProfileDetailsPage/components/profileCard/ProfileCard.vue";
import { userFacade } from "@/store/modules/user/user.facade";
import router from "@/router";
import { ERoutes } from "@/core/dataTypes/enum";
import { i18n } from "@/locales/i18n";
import { getLanguageStaticRoute } from "@/core/utils/router.util";
import { commonFacade } from "@/store/modules/common/common.facade";

export default defineComponent({
  name: "ProfileDetails",
  components: {
    ProfileCard,
  },
  setup() {
    const user = computed(userFacade.user);
    document.title = `${user.value?.customerName} ${i18n.tc(
      "PROFILE_DETAIL_PAGE.TITLE.PAGE_TITLE"
    )}`;
    const defaultBillingAddress = computed(userFacade.defaultBillingAddress);
    const billingAddressList = computed(userFacade.billingAddressList);
    const maxBillingAddressSize = computed(userFacade.maxBillingAddressSize);
    const contactCategoryLink = computed(commonFacade.contactCategory);
    const onProfileSettings = () => {
      router.push(getLanguageStaticRoute(ERoutes.CHANGE_PROFILE_DETAILS));
    };

    const onChangePassword = () => {
      router.push(getLanguageStaticRoute(ERoutes.CHANGE_PASSWORD));
    };

    const onEmailChange = () => {
      router.push(getLanguageStaticRoute(ERoutes.CHANGE_EMAIL));
    };

    const addNewBillingAddress = () => {
      router.push(
        getLanguageStaticRoute(
          `${ERoutes.PROFILE}/${ERoutes.ADD_NEW_BILLING_ADDRESS}`
        )
      );
    };
    const modifyBillingAddress = (customerAddressId: string) => {
      router.push(
        getLanguageStaticRoute(
          `${ERoutes.PROFILE}/${ERoutes.MODIFY_BILLING_ADDRESS}/${customerAddressId}`
        )
      );
    };
    userFacade.getCustomer();
    return {
      user,
      defaultBillingAddress,
      billingAddressList,
      onProfileSettings,
      onChangePassword,
      onEmailChange,
      addNewBillingAddress,
      modifyBillingAddress,
      maxBillingAddressSize,
      contactCategoryLink,
    };
  },
});
