







































































import { computed, defineComponent, ref } from "@vue/composition-api";
import { DialogResultType } from "@/core/dataTypes/types";
import dialogService from "@/core/services/dialog.service";
import { userFacade } from "@/store/modules/user/user.facade";
import Address from "@/views/ProfilePage/ProfileDetailsPage/components/Address/Address.vue";
import { i18n } from "@/locales/i18n";

export default defineComponent({
  name: "ProfileCard",
  props: {
    address: Object,
    id: {
      type: String,
      required: true,
    },
    defaultAddress: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const deleteBillingAddressLoading = computed(
      userFacade.deleteBillingAddressLoading
    );

    const isDeleteBillingAddressLoading = ref(false);

    const onDeleteAddress = (): void => {
      dialogService
        .confirmDialog({
          maxWidth: 424,
          componentProps: {
            title: i18n.tc("PROFILE_CARD.TITLE"),
            content: i18n.tc("PROFILE_CARD.CONTENT"),
            submitText: i18n.tc("PROFILE_CARD.SUBMIT_TEXT"),
            cancelText: i18n.tc("PROFILE_CARD.CANCEL"),
            component: Address,
            componentProps: {
              name: props.address?.billingName,
              address: props.address,
            },
          },
        })
        .$on("dialogResult", (result: DialogResultType): void => {
          if (result.payload) {
            userFacade.deleteBillingAddress(props.address?.customerAddressId);
            isDeleteBillingAddressLoading.value = true;
          }
        });
    };

    const onModifyBillingAddress = () => {
      emit("modifyBillingAddress", props.address?.customerAddressId);
    };

    return {
      onDeleteAddress,
      deleteBillingAddressLoading,
      isDeleteBillingAddressLoading,
      onModifyBillingAddress,
    };
  },
});
